import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const CloserLook = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Me/ghr.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [handleCloserLook, setHandleCloserLook] = useState(false);

  // Intersection observer to trigger animations.

  const { ref, inView } = useInView({
    threshold: 0.2,
    // delay: 100,
  });

  // Handle triggering animations.

  useEffect(() => {
    if (inView) {
      setHandleCloserLook(true);
    }
  }, [handleCloserLook, inView]);

  return (
    <section ref={ref} id="closer-look-section">
      {/* main hér? Vegna SEO? Kjarnin um mig? og headline h1? */}
      <div className="hero-heading-box">
        <h2 className={handleCloserLook ? "hero-heading-smaller" : "unactive"}>
          A closer look
        </h2>
      </div>
      <div className="closer-look-content">
        <div className={handleCloserLook ? "closer-look-txt" : "unactive"}>
          <h1 className="main-heading">
            "A junior front-end web developer with a knack for design and a keen
            interest in programming."
          </h1>
          <p className="main-text">
            I’m a junior front-end web developer with a knack for design and a
            keen interest in programming. I have an ever-growing Javascript
            base, mixed with a solid HTML and CSS foundation. I usually use JS
            frameworks like React and Gatsby to build my projects, but I’m
            always open to adding new tools to my belt.
          </p>
        </div>
        <Img
          className={handleCloserLook ? "me-img" : "unactive"}
          fluid={data.image.childImageSharp.fluid}
          alt="Photo of a man leaning against a wall"
        ></Img>
      </div>
    </section>
  );
};

export default CloserLook;
